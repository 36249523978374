import { FeatureType, FeatureTypeInKorean } from '@/config/endpoint/types/feature';
import themeVariables from '@/config/theme/themeVariable';
import { useInViewport, useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage, useReactCompareSliderRef } from 'react-compare-slider';

export default function Features() {
  const isMobile = useMediaQuery(themeVariables.layout.mobile);

  return (
    <div className='max-w-[1280px] pt-[100px] mx-auto sm:pt-[120px] pb-[40px]'>
      <div id={FeatureType.RemoveFurnitures} className='w-full flex gap-12 flex-col md:flex-row py-12 my-4 '>
        <CompareSlider
          imgSet={[{
            before: 'https://s3.amazonaws.com/aihd-before/d1ab1fbe-1e88-470d-9910-29d616334df9.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA36OM4BMJTSACVHPZ%2F20250106%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20250106T082733Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=fe0b76930b615b73fdcd8eb3376cb2a8d67a1055ad2ba886e08e4b647f882dfe',
            after: 'https://gtemp1.promeai.pro/upload/_asset/u/597959926187269/2025/01/06/d9761765275d4e84a943c56a25c57123.png?URLPrefix=aHR0cHM6Ly9ndGVtcDEucHJvbWVhaS5wcm8vdXBsb2FkL19hc3NldC91LzU5Nzk1OTkyNjE4NzI2OS8yMDI1LzAxLzA2L2Q5NzYxNzY1Mjc1ZDRlODRhOTQzYzU2YTI1YzU3MTIzLnBuZw==&Expires=1736757374&KeyName=promeai-i&Signature=Cnh-3FpLN9rkjKUVcSPxdZYiY0I='
          }]}
          txtPosition='right'
          title={FeatureTypeInKorean[FeatureType.RemoveFurnitures]}
          description='공간의 진정한 모습이 궁금하신가요? AI가 모든 가구와 소품을 깔끔하게 지워드려요. 완전히 비워진 공간에서 새로운 인테리어의 가능성을 마음껏 상상해보세요!'
        />
      </div>

      <div id={FeatureType.InteriorRemodeling} className='w-full flex gap-12 flex-col md:flex-row py-12 my-4 '>
        <CompareSlider
          imgSet={[{
            before: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/11/04/dfff3f27d48448fcb1e713f2c74a63a1.jpg&x-oss-process=image/format,webp&sign=bd2efdcbfa0f2c391c4e54689cfae58e',
            after: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/11/04/dc2ae7ee3a6d416a9e6835c39360e6db.jpg&x-oss-process=image/watermark,image_ZGVmYXVsdA,t_100,P_20,g_south,y_55/format,webp&sign=3d8709e799cd1685ec581c24b1192f53'
          },{
            before: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/12/09/bfec6be55c5a4e849e0cb833d06f47a8.jpg&x-oss-process=image/format,webp&sign=e594054544ba3bcc8ddbad1b7e012982',
            after: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/12/09/b2761d891d444085879350ea5f6c7c7d.jpg&x-oss-process=image/watermark,image_ZGVmYXVsdA,t_100,P_20,g_south,y_131/format,webp&sign=ef99f8cd042e8ccda011bebdb3d049d0'
          },{
            before: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/12/17/ca2be2c5282d4c09b3c83ab41fbd0dc8.jpg&x-oss-process=image/watermark,image_ZGVmYXVsdA,t_100,P_20,g_south,y_32/format,webp&sign=82d1fdac113f48b7e39d671a1c4c977c',
            after: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/12/17/fa806ad45545427f8ea6dc8d3b2a01fd.jpg&x-oss-process=image/watermark,image_ZGVmYXVsdA,t_100,P_20,g_south,y_32/format,webp&sign=e47e7e8d705cbc8d410382cc9f77409d'
          }]}
          txtPosition={isMobile ? 'right' :'left'}
          title={FeatureTypeInKorean[FeatureType.InteriorRemodeling]}
          description='지금 살고 계신 공간을 새롭게 꾸미고 싶으신가요? AI로 먼저 확인해보세요. 모던한 스타일부터 클래식한 분위기까지, 실제 시공 전에 다양한 인테리어를 미리 체험해볼 수 있어요. 당신의 취향에 꼭 맞는 새로운 공간을 찾아드릴게요.'
        />
      </div>

      <div id={FeatureType.SketchRendering} className='w-full flex gap-12 flex-col md:flex-row py-12 my-4 '>
        <CompareSlider
          imgSet={[{
            before: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/10/19/174c13625e364ffebf45303d8dbf7c01.jpg&x-oss-process=image/format,webp&sign=5f1d7324a4d403a3e27871716de55f3f',
            after: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/10/19/33aa738f93db45a1b299ee073866bf74.jpg&x-oss-process=image/watermark,image_ZGVmYXVsdA,t_100,P_20,g_south,y_79/format,webp&sign=a3745219dbd886c0e273ebb7b3357b3f'
          },{
            before: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/12/31/117c19c8aaa047ac97edfb2ba63d550f.jpg&x-oss-process=image/format,webp&sign=4217690520537ba3363592745460d348',
            after: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/12/31/f3a98091a6b8482f8908d910700c72ed.jpg&x-oss-process=image/watermark,image_ZGVmYXVsdA,t_100,P_20,g_south,y_33/format,webp&sign=248fba77aa40fba1b9ef2748377d113c'
          },{
            before: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/03/08/e0e41238a88c41f580d77baa4042f209.png&x-oss-process=image/watermark,image_ZGVmYXVsdA,t_100,P_20,g_south,y_55/format,webp&sign=a1d3d1276b6c8cc4c8adb2092182bfa6',
            after: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/03/08/96c7e12455e24b60a28716658ec931ee.webp&x-oss-process=image/format,webp&sign=98c5ee60d80377f45630e1beba349871'
          },{
            before: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/07/16/8df3c38ed2fb4537a3284fa57b630ec1.jpg&x-oss-process=image/format,webp&sign=0af50839ff22e3f5410a60bc12320c33',
            after: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/07/16/7dfd664e14cd4ff590421c0182afc815.jpg&x-oss-process=image/watermark,image_ZGVmYXVsdA,t_100,P_20,g_south,y_49/format,webp&sign=525775a83a27720a266aea8f10165401'
          },{
            before: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/07/19/0fad62b3d31146988e29e6a2274cb516.jpg&x-oss-process=image/format,webp&sign=66a671c9dab85033c837fd397f4ca617',
            after: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/07/19/1543f54b63764edaba6b78c9d889752a.jpg&x-oss-process=image/watermark,image_ZGVmYXVsdA,t_100,P_20,g_south,y_107/format,webp&sign=a848e876aa2123ffd5f6b0a595ed6b98'
          }]}
          txtPosition='right'
          title={FeatureTypeInKorean[FeatureType.SketchRendering]}
          description='머릿속 아이디어를 현실로 만들어드려요! 간단한 스케치만 있다면, AI가 놀랍도록 사실적인 이미지로 바꿔드립니다. 당신이 상상하는 공간을 더 선명하게 보여드릴게요.'
        />
      </div>

      <div id={FeatureType.FloorplanRendering} className='w-full flex gap-12 flex-col md:flex-row py-12 my-4 '>
        <CompareSlider
          imgSet={[{
            before: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/10/16/5c7ccd3e958e4e0495e244f97200d4f7.jpg&x-oss-process=image/format,webp&sign=47ed1b62f23e4739650980d99d402803',
            after: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/10/16/8b71931812a54d7ea8e888dcb901060f.jpg&x-oss-process=image/watermark,image_ZGVmYXVsdA,t_100,P_20,g_south,y_37/format,webp&sign=3ed4f5458421e45358a5669073a8b3b5'
          },{
            before: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/06/13/03ea60e11b5d4b36b08bfc1e032f6bd1.jpg&x-oss-process=image/format,webp&sign=dc32694956beb441b2f01353793f57c0',
            after: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/06/13/1c3eaf4b61a74b3880d12206dcf6f3e3.jpg&x-oss-process=image/watermark,image_ZGVmYXVsdA,t_100,P_20,g_south,y_62/format,webp&sign=b55e50fef7b1a4a6ae55a3b5407da5e1'
          }]}
          txtPosition={isMobile ? 'right' :'left'}
          title={FeatureTypeInKorean[FeatureType.FloorplanRendering]}
          description='복잡해 보이는 평면도, 이해하기 어려우신가요? 걱정마세요! 2D 도면을 누구나 쉽게 이해할 수 있는 생생한 3D 이미지로 보여드려요. 실제 공간이 어떤 모습일지 한눈에 확인하실 수 있답니다.'
        />
      </div>

      <div id={FeatureType.ExteriorRemodeling} className='w-full flex gap-12 flex-col md:flex-row py-12 my-4 '>
        <CompareSlider
          imgSet={[{
            before: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2025/01/05/3a204603338d423fa54f289953b1fdcf.png&x-oss-process=image/format,webp&sign=262fa722c14ea295ed6bb03a35473b4c',
            after: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2025/01/05/d47dc280a5014d36997880b4c47ba18e.jpg&x-oss-process=image/watermark,image_ZGVmYXVsdA,t_100,P_20,g_south,y_38/format,webp&sign=6f4ce1bb01b7c385dad965a9768bb53e'
          },{
            before: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/09/14/00979622591d408a9bd6ebac26d210b4.jpg&x-oss-process=image/format,webp&sign=bcb39a170f79f6b86f3f82c5ae8ea72d',
            after: 'https://imagef2.promeai.pro/process/do?sourceUrl=/g/p/gallery/publish/2024/09/14/4a59155b8fae41c9b1626ba3aad32a68.jpg&x-oss-process=image/watermark,image_ZGVmYXVsdA,t_100,P_20,g_south,y_119/format,webp&sign=b40037bb5d718b643705351a74f99071'
          }]}
          txtPosition='right'
          title={FeatureTypeInKorean[FeatureType.ExteriorRemodeling]}
          description='건물 외관도 새롭게 변신할 수 있어요! 지금과는 전혀 다른 멋진 모습을 상상해보세요. 현대적인 스타일부터 클래식한 디자인까지, 다양한 변신을 시도해보고 가장 마음에 드는 모습을 찾아보세요.'
        />
      </div>
    </div>
  )
}

const CompareSlider = ({ imgSet, txtPosition, title, description, animation }: { imgSet: any, txtPosition: 'left' | 'right', title: string, description: string, animation?: boolean }) => {
  const { ref, inViewport } = useInViewport();
  const [ animated, setAnimated ] = useState(false);
  const [ selectedImgIndex, setSelectedImgIndex ] = useState(0)

  const reactCompareSliderRef = useReactCompareSliderRef();

  useEffect(() => {
    if (!animation || animated) {
      return
    }
    const fireTransition = async () => {
      await new Promise(resolve => setTimeout(() => {
        reactCompareSliderRef?.current?.setPosition(65);
        resolve(true);
      }, 750));
      await new Promise(resolve => setTimeout(() => {
        reactCompareSliderRef?.current?.setPosition(35);
        resolve(true);
      }, 750));
      await new Promise(resolve => setTimeout(() => {
        reactCompareSliderRef?.current?.setPosition(50);
        resolve(true);
      }, 750));
    };

    fireTransition();
    setAnimated(true);
  }, [inViewport]);

  return (
    <>
      { txtPosition == 'left' &&
        <div className='mr-auto md:ml-4 py-4 flex flex-col gap-0 px-4 md:px-0'>
          <div className='text-gray-800 font-bold text-3xl'>{title}</div>
          <div className='text-gray-500 text-[15px] mt-5'>{description}</div>
          <div className='flex flex-row mt-auto gap-1 pt-4'>
            {imgSet.map((item: any, index: number) => (
              <div key={index}>
                <img
                  src={item?.after}
                  className='w-20 h-14 rounded-lg object-cover cursor-pointer'
                  onClick={() => setSelectedImgIndex(index)}
                />
              </div>
            ))}
          </div>
        </div>
      }
      <div ref={ref} className='flex items-center px-2 justify-center w-full rounded-2xl'>
        <ReactCompareSlider
          ref={reactCompareSliderRef}
          boundsPadding={0}
          transition="0.5s ease"
          className='md:h-[360px] md:w-[600px] rounded-2xl'
          itemOne={
            <div className='flex items-center w-full h-full justify-start'>
              <div className='absolute py-2 px-2.5 m-2 rounded-xl text-sm backdrop-blur-lg bg-black/40 text-white font-medium'>전</div>
              <ReactCompareSliderImage src={imgSet[selectedImgIndex].before} />
            </div>
          }
          itemTwo={
            <div className='flex items-center w-full h-full justify-end'>
              <div className='absolute py-2 px-2.5 m-2 rounded-xl text-sm backdrop-blur-lg bg-black/40 text-white font-medium'>후</div>
              <ReactCompareSliderImage src={imgSet[selectedImgIndex].after} />
            </div>
          }
        />
      </div>
      { txtPosition == 'right' &&
        <div className='ml-auto md:mr-4 py-4 flex flex-col gap-0 px-4 md:px-0'>
          <div className='text-gray-800 font-bold text-3xl'>{title}</div>
          <div className='text-gray-500 text-[15px] mt-5'>{description}</div>
          <div className='flex flex-row mt-auto gap-1 pt-4'>
            {imgSet.map((item: any, index: number) => (
              <div key={index}>
                <img
                  src={item?.after}
                  className='w-20 h-14 rounded-lg object-cover cursor-pointer'
                  onClick={() => setSelectedImgIndex(index)}
                />
              </div>
            ))}
          </div>
        </div>
      }
    </>
  )
}
