export enum FeatureType {
  RemoveFurnitures = 'remove-furnitures',
  InteriorRemodeling = 'interior-remodeling',
  SketchRendering = 'sketch-rendering',
  FloorplanRendering = 'floorplan-rendering',
  ExteriorRemodeling = 'exterior-remodeling',
}

export const FeatureTypeInKorean = {
  'remove-furnitures': '공간 정리하기',
  'interior-remodeling': '실내 인테리어 리모델링',
  'sketch-rendering': '스케치 렌더링',
  'floorplan-rendering': '평면도 입체화',
  'exterior-remodeling': '건물 외관 리모델링'
}