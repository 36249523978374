export const FAQS = [
  { 
    question: 'AI는 어떻게 작동되나요?',
    answer: 'Dspace의 AI는 고급 머신 러닝 알고리즘을 사용하여 업로드된 이미지나 스케치를 분석합니다. 스타일, 색상, 방 유형에 대한 선호도를 기반으로 여러 디자인 옵션을 생성하며, 레이아웃, 가구 배치, 인테리어 디자인 원칙 등을 고려하여 현실적이고 아름다운 방 디자인을 만들어냅니다.'
  },
  { 
    question: '작업 소요 시간은 얼마나 걸리나요?',
    answer: '이미지 처리에 필요한 모든 과정이 1분내로 완료할 수 있습니다. 빠른 시간 내에 결과물을 받아볼 수 있어 시간 효율성이 뛰어납니다.'
  },
  { 
    question: '한번 무료로 사용해볼 수 있나요?',
    answer: '네 물론입니다. 현재 Dspace는 무료 플랜을 지원하고 있습니다. 다만 무료 플랜에서는 기능적인 제한이 있을 수 있다는 점 참고 부탁드립니다.'
  },
  { 
    question: '한달 내에 사용하지 못한 크레딧은 어떻게 되나요?',
    answer: '한달 내에 사용하지 못한 크레딧은 해당 달에 소멸되며, 매월 1일 새로운 크레딧이 부여됩니다.'
  },
  { 
    question: '이용 중 구독을 자유롭게 취소할 수 있나요?',
    answer: '서비스가 마음에 들지 않거나 개인적인 이유 등으로 서비스 구독을 언제든지 자유롭게 취소하실 수 있습니다. 멤버쉽 구독 취소시 해당 달의 남아 있는 기간까지만 기능을 사용하실 수 있으며, 그 이후부터는 더 이상 금액이 청구되지 않습니다.'
  },
  { 
    question: '구독 중 멤버십 플랜을 변경할 수 있나요?',
    answer: '멤버십 플랜은 언제든지 변경하실 수 있습니다. 멤버쉽 플랜 변경 시 새로운 플랜으로 멤버쉽이 새롭게 시작 되며, 기존 플랜의 요금은 일할 계산하여 부분 환불된다는 점 알려드립니다.'
  },
  { 
    question: '위에는 없는 질문이 있는데 어디로 문의하면 될까요?',
    answer: '서비스와 관련하여 궁금하신 점이 있으시다면 언제든지 편하게 contact@definition-lab.com 으로 연락주세요. 빠르게 확인 후 답변드리도록 하겠습니다.'
  },
]
